





































































import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import {Mixins, Prop} from 'vue-property-decorator'
import ECharts from 'vue-echarts/components/ECharts.vue'
import 'echarts/lib/chart/bar.js'
import {EnvHelper} from '@/helpers/EnvHelper'
import {GasCalculatorTargetCollection} from '@/model/collection/GasCalculatorTargetCollection'

@Component({
  components: {
    ECharts,
  },
})
export default class GasCalculatorChart extends Mixins(MixinScreenSize) {
  @Prop({type: GasCalculatorTargetCollection, required: true})
  gasCalculatorTargetCollection!: GasCalculatorTargetCollection

  get rich() {
    const obj: Record<string, any> = {}

    this.gasCalculatorTargetCollection.memberAndCouncil.forEach(
      ({scriptHash, imageUrl}, index) => {
        if (!scriptHash || index === 0) return

        obj[scriptHash] = {
          height: 20,
          width: 20,
          borderRadius: 10,
          shadowOffsetY: 4,
          shadowBlur: 6,
          shadowColor: 'rgba(0, 0, 0, 0.06)',
          backgroundColor: {
            image: imageUrl,
          },
        }
      }
    )

    return obj
  }

  get options() {
    const items = this.gasCalculatorTargetCollection.memberAndCouncil
    const xAxis = items
      .map(({name}) => name?.trim())
      .filter((name): name is string => name !== undefined)

    const seriesVotes = items
      .map(({numberOfVotes}) => numberOfVotes)
      .filter((votes): votes is number => votes !== undefined)

    const seriesGas = items.map(({dailyGasRate}) =>
      (Number(dailyGasRate) * 100).toFixed(8)
    )

    const biggerName = xAxis.length
      ? xAxis.reduce((a, b) => (a.length > b.length ? a : b))
      : ''
    const biggerVote = seriesVotes.length
      ? seriesVotes
          .map(i => String(i))
          .reduce((a, b) => (a.length > b.length ? a : b))
      : ''

    return {
      grid: {
        bottom: 20 + 8 * biggerName.length,
        left: 12 * biggerVote.length,
        top: 30,
        right: 60,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: EnvHelper.AQUAMARINE_COLOR,
            opacity: 0.2,
          },
        },
        position: function(pos: number[], params: any, dom: Element) {
          try {
            const container = document.querySelector(
              '.gas-calculator-chart'
            ) as HTMLDivElement

            const containerWidth = container.clientWidth - 64 // 64 is the padding of the container plus a little offset

            const domWidth = dom.clientWidth

            const top = pos[1] + 10
            const left = pos[0]

            if (containerWidth - left < domWidth) {
              return [left - 10 - domWidth, top]
            }

            return [left + 10, top]
          } catch {
            return
          }
        },
        backgroundColor: `${EnvHelper.REGENT_GRAY_COLOR}CC`,
        formatter: `{b}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${EnvHelper.ILLUSION_COLOR}"></span>{c0}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${EnvHelper.AQUAMARINE_COLOR}"></span>{c1}`,
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        offset: 1,
        axisLine: {
          lineStyle: {
            cap: 'round',
            width: 2,
          },
          onZero: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          rotate: 90,
          formatter: (value: any) => {
            const node = this.gasCalculatorTargetCollection.items.find(
              ({name}) => name === value
            )
            if (!node) {
              return value
            }

            return `{value|${value}} {${node.scriptHash}|}`
          },
          rich: this.rich,
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            color: EnvHelper.REGENT_GRAY_COLOR,
            formatter: (value: number) =>
              value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ''), // Remove commas
          },
          interval: 2500000,
          max: 12500000,
          axisLine: {
            show: true,
            lineStyle: {
              color: EnvHelper.REGENT_GRAY_COLOR,
              cap: 'round',
              width: 2,
            },
          },
          splitLine: {
            show: false,
          },
        },
        {
          type: 'value',
          axisLabel: {
            color: EnvHelper.REGENT_GRAY_COLOR,
            formatter: (value: number) => value.toFixed(3),
          },
          interval: 0.025,
          max: 0.125,
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: this.$t(
            'components.gasCalculator.gasCalculatorChart.numberOfVotes'
          ),
          type: 'bar',
          yAxisIndex: 0,
          barWidth: 10,
          barGap: 0.5,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: EnvHelper.ILLUSION_COLOR,
          },
          data: seriesVotes,
        },
        {
          name: this.$t(
            'components.gasCalculator.gasCalculatorChart.dailyGasRewards'
          ),
          type: 'bar',
          yAxisIndex: 1,
          barWidth: 10,
          barGap: 0.5,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: EnvHelper.AQUAMARINE_COLOR,
          },
          data: seriesGas,
        },
      ],
    }
  }
}
